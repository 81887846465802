exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-activities-index-js": () => import("./../../../src/pages/activities/index.js" /* webpackChunkName: "component---src-pages-activities-index-js" */),
  "component---src-pages-activities-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/activities/{markdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-activities-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-chapter-computer-society-index-js": () => import("./../../../src/pages/chapter/computer-society/index.js" /* webpackChunkName: "component---src-pages-chapter-computer-society-index-js" */),
  "component---src-pages-chapter-education-society-index-js": () => import("./../../../src/pages/chapter/education-society/index.js" /* webpackChunkName: "component---src-pages-chapter-education-society-index-js" */),
  "component---src-pages-chapter-pes-society-index-js": () => import("./../../../src/pages/chapter/pes-society/index.js" /* webpackChunkName: "component---src-pages-chapter-pes-society-index-js" */),
  "component---src-pages-chapter-wie-society-index-js": () => import("./../../../src/pages/chapter/wie-society/index.js" /* webpackChunkName: "component---src-pages-chapter-wie-society-index-js" */),
  "component---src-pages-execom-execom-json-year-js": () => import("./../../../src/pages/execom/{execomJson.year}.js" /* webpackChunkName: "component---src-pages-execom-execom-json-year-js" */),
  "component---src-pages-execom-index-js": () => import("./../../../src/pages/execom/index.js" /* webpackChunkName: "component---src-pages-execom-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magnathon-js": () => import("./../../../src/pages/magnathon.js" /* webpackChunkName: "component---src-pages-magnathon-js" */),
  "component---src-pages-reg-index-js": () => import("./../../../src/pages/reg/index.js" /* webpackChunkName: "component---src-pages-reg-index-js" */),
  "component---src-pages-reg-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/reg/{markdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-reg-markdown-remark-frontmatter-slug-js" */)
}

